<template>
	<div>
		<CCard class="p-3"> 
			<CCardHeader>
				<CRow>
					<CButton size="sm" class="btn btn-link back-btn " 
					@click="backToTable()"><i class="fa fa-arrow-left"></i>Back</CButton>
				</CRow>
					<CRow>
			
					<CCol lg="6">
						<h5 style="color: #1352a1;">VIEW FURNISHING</h5>
					</CCol>
			     	
				</CRow>
			</CCardHeader>
			<CCardBody>
			
			<CCard class="p-4 pl-4 pr-4 ml-2 mr-2 mt-2">
				
				<CRow class="p-2 mb-2 bg-secondary text-info">
							<CCol lg="6">
							<strong style="font-size:12px"> FURNISHING DETAILS</strong>
						</CCol>
				</CRow>	
					<CRow class="ml-3 mr-3">
						<CCol lg="3">
							<div class="form-group">
                         	 <small class="">Asset No.</small>
                         	 <strong class="form-text mt-0 text-capitalize">{{dataParams.asset_no ? dataParams.asset_no : "-"}}</strong>
                        	</div>
						</CCol>
						<CCol lg="3">
							<div class="form-group">
                         	 <small class="">Type</small>
                         	 <strong class="form-text mt-0 text-uppercase">{{dataParams.type ? dataParams.type : "-"}}</strong>
                        	</div>
						</CCol>
						
						<CCol lg="3">
							<div class="form-group">
                         	 <small class="">Model</small>
                         	 <strong class="form-text mt-0 text-uppercase">{{dataParams.model ? dataParams.model : "-"}}</strong>
                        	</div>
						</CCol>
						<CCol lg="3">
							<div class="form-group">
                         	 <small class="">Status</small>
                         	 <strong class="form-text mt-0 text-uppercase">{{dataParams.status ? dataParams.status : "-"}}</strong>
                        	</div>
						</CCol>
						
						
					
					</CRow>
					<CRow class="ml-3 mr-3" style="margin-top: -14px;">
						<CCol lg="3">
							<div class="form-group">
                         	 <small class="">Specification</small>
                         	 <strong class="form-text mt-0 text-capitalize">{{dataParams.specification ? dataParams.specification : "-"}}</strong>
                        	</div>
						</CCol>
						<CCol lg="3">
							<div class="form-group">
                         	 <small class="">Dimension</small>
                         	 <strong class="form-text mt-0 text-capitalize">{{dataParams.dimentsion ? dataParams.dimentsion : "-"}}</strong>
                        	</div>
						</CCol>
						
						<CCol lg="3">
							<div class="form-group">
                         	 <small class="">Serial No.</small>
                         	 <strong class="form-text mt-0 text-capitalize">{{dataParams.serial_no ? dataParams.serial_no : "-"}}</strong>
                        	</div>
						</CCol>
						<CCol lg="3">
							<div class="form-group">
                         	 <small class="">MFG/Brand</small>
                         	 <strong class="form-text mt-0 text-capitalize">{{dataParams.mfg_brand ? dataParams.mfg_brand : "-"}}</strong>
                        	</div>
						</CCol>
						
					 
					</CRow>
					<CRow class="ml-3 mr-3" style="margin-top: -14px;">
						<CCol lg="3">
							<div class="form-group">
                         	 <small class="">Acquisition Date</small>
                         	 <strong class="form-text mt-0">{{dataParams.acquisition_date ? moment.unix(dataParams.acquisition_date/1000).format('MMMM DD, YYYY') : "-"}}</strong>
                        	</div>
						</CCol>
						<CCol lg="3">
							<div class="form-group">
                         	 <small class="">Acquisition Cost</small>
                         	 <strong class="form-text mt-0">{{dataParams.acquisition_cost ? dataParams.acquisition_cost : "-"}}</strong>
                        	</div>
						</CCol>
						
						<CCol lg="3">
							<div class="form-group">
                         	 <small class="">PO No.</small>
                         	 <strong class="form-text mt-0 text-capitalize">{{dataParams.po_no ? dataParams.po_no : "-"}}</strong>
                        	</div>
						</CCol>
						
					 
					</CRow>
					<CRow class="ml-3 mr-3" style="margin-top:-14px;"> 
						<CCol lg="12">
							<div class="form-group">
                         	 <small class="">Description</small>
                         	 <strong class="form-text mt-0">{{dataParams.description ? dataParams.description : "-"}}</strong>
                        	</div>
						</CCol>
						
					</CRow>
					
					<CRow class="p-2 mb-2 bg-secondary text-info">
							<CCol lg="6">
							<strong style="font-size:12px"> OTHER DETAILS</strong>
						</CCol>
					</CRow>		
					<CRow class="ml-3 mr-3"> 
						<CCol lg="3">
							<div class="form-group">
                         	 <small class="">Origin</small>
                         	 <strong class="form-text mt-0 text-uppercase">{{dataParams.origin ? dataParams.origin.customer_name : "-"}}</strong>
                        	</div>
						</CCol>
						<CCol lg="3">
							<div class="form-group">
                         	 <small class="">Current Location</small>
                         	 <strong class="form-text mt-0 text-uppercase">{{dataParams.current_location ? dataParams.current_location.customer_name : "-"}}</strong>
                        	</div>
						</CCol>
						<CCol lg="3">
							<div class="form-group">
                         	 <small class="">Depot</small>
                         	 <strong class="form-text mt-0">{{dataParams.depot ? dataParams.depot.setting_name : "-"}}</strong>
                        	</div>
						</CCol>
						
					</CRow>
					<CRow class="ml-3 mr-3" style="margin-top:-14px;"> 
						<CCol lg="3">
							<div class="form-group">
                         	 <small class="">Rental Rate</small>
                         	 <strong class="form-text mt-0">{{dataParams.rental_rate ? dataParams.rental_rate : "-"}}</strong>
                        	</div>
						</CCol>
						<CCol lg="3">
							<div class="form-group">
                         	 <small class="">Transfer Rate</small>
                         	 <strong class="form-text mt-0">{{dataParams.late_transfer_date ? moment.unix(dataParams.late_transfer_date).format('MMMM DD, YYYY') : "-"}}</strong>
                        	</div>
						</CCol>
						<CCol lg="3">
							<div class="form-group">
                         	 <small class="">MTS No.</small>
                         	 <strong class="form-text mt-0">{{dataParams.mts_no ? dataParams.mts_no : "-"}}</strong>
                        	</div>
						</CCol>
						   
					</CRow>
					
					<CRow class="ml-3 mr-3" style="margin-top:-14px;"> 
						<CCol lg="12">
							<div class="form-group">
                         	 <small class="">Remarks</small>
                         	 <strong class="form-text mt-0">{{dataParams.remarks ? dataParams.remarks : "-"}}</strong>
                        	</div>
						</CCol>
						
					</CRow> 
					<br/>
					<CRow class="ml-3 mr-3" style="margin-top:-14px;"> 
						<CCol lg="4"> 
							
								<label for="default_photo" style="max-width:180px; overflow: hidden;"> 
									Photo:
							<!-- <label for="default_photo" style="max-height: 180px; overflow: hidden; display: block; margin-top: 2px"> -->
								<img style="width: 100%; cursor: pointer" id="display_default_photo" src="https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg" v-if="!dataParams.photo">
								<img style="width: 100%; cursor: pointer" id="display_default_photo" :src="config.main_path.replace('/index.php', '')+'/uploads/'+dataParams.photo" v-else>
							</label>
						</CCol>  
					</CRow>
			
				</CCard>
			</CCardBody> 
		</CCard>
		
	</div>
</template>
<script> 
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
// import 'vue-select/dist/vue-select.css';
import '../../style.css';
import moment from 'moment';
// import HistoryModal from './history_modal/index';

export default {
	mounted(){  

		this.getData(); 

	},
	data(){
		return{
			formModal: false,
			showViewModal: false,
			// showHistoryModal: false,
			dataList: [],
			dataListInactive: [],
			depotName: "",
			dataParams: {
				customer_id: "",
				origin_id: "",
				commodity_id: "",
				ref_no: "",
				status: "",
				tax_type: "",
				delivery_date_from: "",
				delivery_date_to: "",
				total_amount: 0.00,
				bookings: []
			},
			editMode: false, 
			vendorList: {
				data: []
			},
			vehicleTypeList: {
				data: []
			},
			makerModelList: {
				data: []
			},
			depotList: {
				data: []
			},
			originList: {
				data: []
			},
			isLoading: false,
			uploadPercentage: 0,
			config,
			moment,
			fields:  [
				{
					key: 'booking_no', 
					label: 'Booking No.'
				},
				{
					key: 'customer_id_label', 
					label: 'Customer'
				},
				{
					key: 'vehicle_type_id_label', 
					label: 'Trucker'
				}, 
				{
					key: 'delivery_date_label', 
					label: 'Delivery Date'
				}, 
				{
					key: 'booking_type_id_label', 
					label: 'Booking Type'
				},
				{
					key: 'commodity_id_label', 
					label: 'Commodity'
				},
				{
					key: 'plate_no', 
					label: 'Plate No.'
				}, 
				{
					key: 'origin_id_label', 
					label: 'Origin'
				}, 
				{
					key: 'destination_id_label', 
					label: 'Destination'
				}, 
				{
					key: 'no_of_trips', 
					label: 'No. of trips'
				}, 
				{
					key: 'trip_rates', 
					label: 'Trip Rates'
				},
				{
					key: 'total_amount', 
					label: 'Total Amount'
				}
			],
		}
	},
	name: 'Tables',
	components: {Search, Datepicker, vSelect},
	methods: { 

		getData(){
			this.$showLoading(true)

			axios.get(config.api_path+"/furnishing/"+this.$route.params.id)
			.then(response=>{  
				this.$showLoading(false)
				this.dataParams = response.data.data;   
				if(this.dataParams.acquired_date){
					this.dataParams.acquired_date = new Date(this.dataParams.acquired_date * 1000);
				}
				if(this.dataParams.acquisition_date){
					this.dataParams.acquisition_date = new Date(this.dataParams.acquisition_date * 1000);
				}
				if(this.dataParams.transfer_date){
					this.dataParams.transfer_date = new Date(this.dataParams.transfer_date * 1000);
				} 

				for (var key in this.dataParams) {
				    if (this.dataParams.hasOwnProperty(key)) {
				    	console.log(key)
				        if(this.dataParams[key] == "null" || this.dataParams[key] == null){
				        	this.dataParams[key] = ""
				        }
				    }
				}

			})

		},   

	    updateCompanyOwned(e){
	    	this.dataParams.company_owned = e;
	    },

	    getStatus(status){

	    	if(status == "active"){return "Active";}
	    	if(status == "inactive"){return "Inactive";}
	    	if(status == "incoming"){return "Incoming";}
	    	if(status == "archive"){return "Archive/Sold";} 

	    },

	    transimission(trans){
	    	if(trans == "manual"){return "Manual";}
	    	if(trans == "automatic"){return "Automatic";}
	    },

	    fuelType(fuel){
	    	if(fuel == "gasoline"){return "Gasoline";}
	    	if(fuel == "diesel"){return "Diesel";}
	    },

	    backToTable(){
	    	this.$router.push('/main/furnishing')
	    }

 	}
}
</script>
